import BillPayments from "./componets/BillPayments/BillPayments";
import CrossBorderPayments from "./componets/CrossBorderPayments/CrossBorderPayments";
import CurrencySwap from "./componets/CurrencySwap/CurrencySwap";
import Footer from "./componets/Footer/Footer";
import Header from "./componets/Header/Header";
import HeroSection from "./componets/HeroSection/HeroSection";

import OverTheCounter from "./componets/OverTheCounter/OverTheCounter";
import PaymentGateWay from "./componets/PaymentGateWay/PaymentGateWay";
import SecureVault from "./componets/SecureVault/SecureVault";
import SportTrading from "./componets/SportTrading/SportTrading";
import { arrow } from "./images";
import styles from "./App.module.css";

function App() {
  return (
    <>
      <Header />
      <HeroSection />
      <div className={styles.wrapper}>
        <BillPayments />
        <img src={arrow} alt="" className={styles.arrow} />
        <CrossBorderPayments />{" "}
        <img src={arrow} alt="" className={styles.arrow} />
        <PaymentGateWay />
        <img src={arrow} alt="" className={styles.arrow} />
        <CurrencySwap />
        <img src={arrow} alt="" className={styles.arrow} />
        <SecureVault />
        <img src={arrow} alt="" className={styles.arrow} />
        <OverTheCounter />
        <img src={arrow} alt="" className={styles.arrow} />
        <SportTrading />
      </div>
      <Footer />
    </>
  );
}

export default App;
